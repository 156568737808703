import '../sass/frontend.scss'

//import $ from 'jquery';
$ = jQuery;
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel';

$(document).ready(function () {

    $('.owl-carousel').owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        // responsive: {
        //     0: {
        //         items: 1
        //     },
        //     600: {
        //         items: 3
        //     },
        //     1000: {
        //         items: 1
        //     }
        // }
    })

})